const authorizeMovement = {
	id: 'authorizeMovement',
	selectionType: 'single',
	label: 'actions.movement.authorizeMovement',
	functionality: 'EDIT_MOVEMENT',
	checkAvailability: function (registries) {
		if (registries.length > 0) {
			const editableStatus = ['AC', 'PL'];
			const isEditable = editableStatus.some((element) => element === registries[0].movementstatuscode);
			return registries[0] && isEditable;
		} else {
			return false;
		}
	},
	runAction: function (action, model, registries) {
		// to open a model dialog
		const row = registries[0];

		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-authorizeMovement-' + model.name + '-show', row);
	}
};

const initMovement = {
	id: 'initMovement',
	selectionType: 'single',
	label: 'actions.movement.initMovement',
	functionality: 'EDIT_MOVEMENT',
	checkAvailability: function (registries) {
		if (registries.length > 0) {
			const editableStatus = ['AC', 'PL', 'AU'];
			const isEditable = editableStatus.some((element) => element === registries[0].movementstatuscode);
			return registries[0] && isEditable;
		} else {
			return false;
		}
	},
	runAction: function (action, model, registries) {
		// to open a model dialog
		const row = registries[0];

		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-initMovement-' + model.name + '-show', row);
	}
};

const finMovement = {
	id: 'finMovement',
	selectionType: 'single',
	label: 'actions.movement.finMovement',
	functionality: 'EDIT_MOVEMENT',
	checkAvailability: function (registries) {
		if (registries.length > 0) {
			return registries[0] && registries[0].movementstatuscode == 'IN';
		} else {
			return false;
		}
	},
	runAction: function (action, model, registries) {
		// to open a model dialog
		const row = registries[0];

		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-finMovement-' + model.name + '-show', row);
	}
};

export default {
	gridactions: [authorizeMovement, initMovement, finMovement],
	formactions: [authorizeMovement, initMovement, finMovement]
};
