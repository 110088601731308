<template>
	<v-row class="pui-form-layout">
		<v-col cols="12">
			<v-row dense class="mt-2">
				<v-col cols="2" v-if="isCreatingElement">
					<pui-select
						:id="`portid-${modelName}`"
						:attach="`portid-${modelName}`"
						:label="$t('form.movement.portname')"
						toplabel
						clearable
						required
						v-model="parentObject"
						modelName="port"
						:modelFormMapping="{ id: 'portid' }"
						:itemsToSelect="itemsToSelectPortid"
						:disabled="formDisabled"
						itemValue="id"
						itemText="portname"
						:order="{ portname: 'asc' }"
						:fixedFilter="filterByPortAuthorityAndPort"
					></pui-select>
				</v-col>
				<v-col cols="6" v-if="isCreatingElement">
					<pui-select
						:id="`visitid-${modelName}`"
						:attach="`visitid-${modelName}`"
						:label="$t('form.movement.portcallnumber')"
						toplabel
						clearable
						required
						:disabled="!enableVisit"
						v-model="parentObject"
						modelName="visit"
						:itemsToSelect="itemsToSelectPortcalls"
						:modelFormMapping="{ id: 'visitid' }"
						:itemValue="['id']"
						:itemText="
							(item) =>
								`${item.portcallnumber} - ${item.vesselname} - ${this.$dateTimeUtils.getLocalFormattedDate(
									item.eta,
									'DD/MM/YYYY HH:mm'
								)} - ${item.status}`
						"
						:order="{ eta: 'asc' }"
						:fixedFilter="filterInactiveVisits"
						reactive
					></pui-select>
				</v-col>
				<v-col cols="2">
					<pui-number-field
						:id="`nummovement-movement`"
						v-model="parentObject.nummovement"
						:label="$t('form.movement.nummovement')"
						:disabled="formDisabled || !enableMovNum"
						required
						toplabel
						maxlength="10"
					></pui-number-field>
				</v-col>
				<v-col cols="3" v-if="!isCreatingElement">
					<pui-select
						:id="`movementstatuscode-${modelName}`"
						:attach="`movementstatuscode-${modelName}`"
						:label="$t('form.movement.status')"
						toplabel
						clearable
						required
						disabled
						v-model="parentObject"
						modelName="movementstatus"
						:itemsToSelect="itemsToSelectMovStatus"
						:modelFormMapping="{ movementstatuscode: 'movementstatuscode' }"
						itemValue="movementstatuscode"
						itemText="name"
						reactive
					></pui-select>
				</v-col>
				<v-col cols="2">
					<pui-select
						:id="`type-${modelName}`"
						:attach="`type-${modelName}`"
						:label="$t('form.movement.type')"
						toplabel
						clearable
						required
						:disabled="formDisabled || disableByStatus"
						v-model="parentObject"
						modelName="movementtype"
						:itemsToSelect="itemsToSelectMovType"
						:modelFormMapping="{ id: 'type' }"
						itemValue="id"
						itemText="name"
						reactive
					></pui-select>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="2">
					<pui-radio-group
						:id="`stopfrom-${modelName}`"
						:label="$t('form.movement.movementOrigin')"
						v-model="parentObject.origin"
						:radios="viewOriginLocationRange"
						:disabled="formDisabled || disableByStatus"
						required
					></pui-radio-group>
				</v-col>
				<v-col cols="4">
					<pui-select
						:id="`locationfrom-${modelName}`"
						:attach="`locationfrom-${modelName}`"
						:label="$t('form.movement.location')"
						:disabled="formDisabled || !enableOriginLocation || disableByStatus"
						toplabel
						clearable
						v-model="parentObject"
						modelName="location"
						:itemsToSelect="itemsToSelectFromLocation"
						:modelFormMapping="{ id: 'locationfrom' }"
						:itemValue="['id']"
						:itemText="(item) => `${item.description}`"
						:order="{ description: 'asc' }"
						:fixedFilter="portidFixedFilter"
						reactive
						:required="parentObject.origin == 'OL'"
						:rows="500"
					></pui-select>
				</v-col>
				<v-col cols="4">
					<pui-select
						:id="`stopfrom-${modelName}`"
						:attach="`stopfrom-${modelName}`"
						:label="$t('form.movement.stopfrom')"
						:disabled="formDisabled || !enableOriginStop || disableByStatus"
						toplabel
						clearable
						v-model="parentObject"
						modelName="stop"
						:itemsToSelect="itemsToSelectFromStop"
						:modelFormMapping="{ id: 'stopfrom' }"
						:itemValue="['id']"
						:itemText="(item) => getStopItemText(item)"
						:key="'visitid_' + visitidKey"
						:order="{ num: 'asc' }"
						showOnlyActives
						reactive
						:fixedFilter="visitidFixedFilter"
						:required="parentObject.origin == 'OS'"
					></pui-select>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="2">
					<pui-radio-group
						:id="`stopto-${modelName}`"
						:label="$t('form.movement.movementDestination')"
						v-model="parentObject.destination"
						:radios="viewDestinationLocationRange"
						:disabled="formDisabled || disableByStatus"
						required
					></pui-radio-group>
				</v-col>
				<v-col cols="4">
					<pui-select
						:id="`locationto-${modelName}`"
						:attach="`locationto-${modelName}`"
						:label="$t('form.movement.location')"
						:disabled="formDisabled || !enableDestinationLocation || disableByStatus"
						toplabel
						clearable
						v-model="parentObject"
						modelName="location"
						:itemsToSelect="itemsToSelectToLocation"
						:modelFormMapping="{ id: 'locationto' }"
						:itemValue="['id']"
						:itemText="(item) => `${item.description}`"
						:order="{ description: 'asc' }"
						:fixedFilter="portidFixedFilter"
						reactive
						:rows="500"
						:required="parentObject.destination == 'DL'"
					></pui-select>
				</v-col>
				<v-col cols="4">
					<pui-select
						:id="`stopto-${modelName}`"
						:attach="`stopto-${modelName}`"
						:label="$t('form.movement.stopto')"
						:disabled="formDisabled || !enableDestinationStop || disableByStatus"
						toplabel
						clearable
						v-model="parentObject"
						modelName="stop"
						:itemsToSelect="itemsToSelectToStop"
						:modelFormMapping="{ id: 'stopto' }"
						:itemValue="['id']"
						:itemText="(item) => getStopItemText(item)"
						:order="{ num: 'asc' }"
						showOnlyActives
						reactive
						:fixedFilter="visitidFixedFilter"
						:required="parentObject.destination == 'DS'"
					></pui-select>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="4" v-if="isCreatingElement">
					<pui-date-field
						:id="`starttime-${modelName}`"
						:label="$t('form.movement.starttime')"
						v-model="parentObject.starttime"
						:disabled="formDisabled"
						toplabel
						:max="parentObject.endtime"
						required
						time
					></pui-date-field>
				</v-col>
				<v-col cols="4" v-if="isCreatingElement">
					<pui-date-field
						:id="`endtime-${modelName}`"
						:label="$t('form.movement.endtime')"
						v-model="parentObject.endtime"
						:disabled="formDisabled"
						:min="parentObject.starttime"
						toplabel
						time
					></pui-date-field>
				</v-col>
				<v-col cols="3" v-if="hasRealDuration">
					<pui-number-field
						:id="`realduration-movement`"
						v-model="parentObject.realduration"
						:label="$t('form.movement.realduration')"
						disabled
						toplabel
						maxlength="10"
					></pui-number-field>
				</v-col>
			</v-row>
			<div v-if="!isCreatingElement">
				<pui-field-set
					v-for="(allocation, index) in parentObject.allocations"
					:key="allocation.id"
					:title="$t('form.movement.formtitle') + ` ${allocation.name.toLowerCase()}`"
				>
					<v-row>
						<v-col cols="4">
							<pui-date-field
								:id="`starttime-${modelName}`"
								:label="$t('form.movement.starttime')"
								v-model="allocation.eta"
								:disabled="index + 1 != parentObject.allocations.length"
								toplabel
								time
							></pui-date-field>
						</v-col>
						<v-col cols="4">
							<pui-date-field
								:id="`endtime-${modelName}`"
								:label="$t('form.movement.endtime')"
								v-model="allocation.etd"
								:disabled="index + 1 != parentObject.allocations.length"
								toplabel
								time
							></pui-date-field>
						</v-col>
					</v-row>
				</pui-field-set>
			</div>
			<v-row dense>
				<v-col cols="4">
					<pui-checkbox
						:id="`pilotage-${modelName}`"
						:label="$t('form.movement.pilotage')"
						v-model="parentObject.pilotage"
						:disabled="formDisabled"
					></pui-checkbox>
				</v-col>
			</v-row>
			<pui-field-set :title="$t('form.movement.pilotagegrid')" v-if="!isCreatingElement && !isModalDialog">
				<pui-master-detail
					componentName="pilotagegrid"
					:parentModelName="modelName"
					:parentPk="pk"
					:parentPkChildFk="{ id: 'movement_id' }"
					:formDisabled="formDisabled"
					:modalDialog="isModalDialog"
					:parentModel="parentObject"
				></pui-master-detail>
			</pui-field-set>
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: 'movement-form-main-tab',
	data() {
		return {
			enableVisit: false,
			visitidKey: 0,
			portidFixedFilter: null,
			visitidFixedFilter: null,
			inactiveVisitStatusCodes: this.$store.getters.getInactiveVisitStatusid,
			initialStatus: 'AC',
			enableMovNum: false,
			nextMovNum: null,
			viewOriginLocationRange: [
				{
					label: this.$t('form.movement.location'),
					id: 'OL'
				},
				{
					label: this.$t('form.movement.stop'),
					id: 'OS'
				}
			],
			viewDestinationLocationRange: [
				{
					label: this.$t('form.movement.location'),
					id: 'DL'
				},
				{
					label: this.$t('form.movement.stop'),
					id: 'DS'
				}
			],
			enableOriginLocation: false,
			enableOriginStop: false,
			enableDestinationLocation: false,
			enableDestinationStop: false,
			locationid: null,
			originalLocationTo: null,
			hasRealDuration: false,
			disableByStatus: false
		};
	},
	props: {
		modelName: {
			type: String,
			required: true
		},
		parentObject: {
			type: Object,
			required: true
		},
		formDisabled: {
			type: Boolean,
			required: true
		},
		isCreatingElement: {
			type: Boolean,
			required: true
		},
		isModalDialog: {
			type: Boolean,
			default: false
		},
		pk: {
			type: [Object, String],
			required: true
		}
	},
	methods: {
		getNextMovementNum() {
			const opts = {
				model: 'movement',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'visitid',
							op: 'eq',
							data: this.parentObject.visitid
						}
					]
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data && response.data.data[0]) {
					let MovementNums = response.data.data.map((item) => item.nummovement);
					let maxMovNum = Math.max(...MovementNums);
					this.nextMovNum = ++maxMovNum;
					this.parentObject.nummovement = this.nextMovNum;
				}
			});
		},
		getLocationFromStop(stop) {
			const opts = {
				model: 'berthbystopstatus',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'id',
							op: 'eq',
							data: stop
						}
					]
				}
			};

			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data && response.data.data[0]) {
					this.locationid = response.data.data[0].berthid;
					if (this.parentObject.origin === 'OS' && !this.parentObject.locationfrom) {
						this.parentObject.locationfrom = this.locationid;
					} else if (this.parentObject.destination === 'DS' && !this.parentObject.locationto) {
						this.parentObject.locationto = this.locationid;
					}
				}
			});
		},
		addInitialOriginRadioButton() {
			if (this.parentObject.stopfrom) {
				this.parentObject.origin = 'OS';
				this.enableOriginStop = true;
			} else {
				this.parentObject.origin = 'OL';
				this.enableOriginLocation = true;
			}
		},
		addInitialDestinationRadioButton() {
			if (this.parentObject.stopto) {
				this.parentObject.destination = 'DS';
				this.enableDestinationStop = true;
			} else {
				this.parentObject.destination = 'DL';
				this.enableDestinationLocation = true;
			}
		},
		getPortIdFromVisitId() {
			const opts = {
				model: 'visit',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'id',
							op: 'eq',
							data: this.parentObject.visitid
						}
					]
				}
			};

			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data && response.data.data[0]) {
					this.parentObject.portid = response.data.data[0].portid;
				}
			});
		},
		getRealDuration() {
			const opts = {
				model: 'movementsequenceheader',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{ field: 'movement_id', op: 'eq', data: this.parentObject.id },
						{ field: 'total_time', op: 'nn', data: null }
					]
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data && response.data.data[0]) {
					this.parentObject.realduration = response.data.data[0].totaltimemin;
					this.hasRealDuration = true;
				}
			});
		},
		getAllocations() {
			const opts = {
				model: 'movementallocation',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [{ field: 'movement_id', op: 'eq', data: this.parentObject.id }]
				},
				order: [{ column: 'id', direction: 'asc' }]
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data && response.data.data[0]) {
					this.parentObject.allocations = response.data.data;
				}
			});
		},
		getStopItemText(item) {
			let startdate = item.ata ? item.ata : item.etaauth ? item.etaauth : item.eta;
			let enddate = item.atd ? item.atd : item.etdauth ? item.etdauth : item.etd;

			return `${item.num} - ${this.$dateTimeUtils.getLocalFormattedDate(startdate, 'DD/MM/YYYY HH:mm')}  ${
				enddate ? '-' + this.$dateTimeUtils.getLocalFormattedDate(enddate, 'DD/MM/YYYY HH:mm') : ''
			} - ${item.status} - ${item.location}`;
		}
	},
	computed: {
		itemsToSelectPortid() {
			return [{ id: this.parentObject.portid }];
		},
		itemsToSelectMovStatus() {
			return [{ movementstatuscode: this.parentObject.movementstatuscode }];
		},
		itemsToSelectMovType() {
			return [{ id: this.parentObject.type }];
		},
		itemsToSelectFromLocation() {
			return [{ id: this.parentObject.locationfrom }];
		},
		itemsToSelectToLocation() {
			return [{ id: this.parentObject.locationto }];
		},
		itemsToSelectFromStop() {
			return [{ id: this.parentObject.stopfrom }];
		},
		itemsToSelectToStop() {
			return [{ id: this.parentObject.stopto }];
		},
		itemsToSelectPortcalls() {
			return [{ id: this.parentObject.visitid }];
		},
		filterInactiveVisits() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'portid', op: 'eq', data: this.parentObject.portid },
					{ field: 'statusid', op: 'ni', data: this.inactiveVisitStatusCodes }
				]
			};
		},
		filterByPortAuthorityAndPort() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'portauthorityid', op: 'eq', data: window.localStorage.getItem('workingPortAuthorityId') },
					{ field: 'id', op: 'in', data: this.$store.getters.getPortsIds }
				]
			};
		}
	},
	created() {
		if (this.isCreatingElement) {
			if (this.$router.currentRoute.path.includes('arrival')) {
				this.parentObject.type = 1;
			} else if (this.$router.currentRoute.path.includes('departure')) {
				this.parentObject.type = 2;
			} else {
				this.parentObject.type = null;
			}
		}
	},
	mounted() {
		if (!this.isCreatingElement) {
			this.addInitialOriginRadioButton();
			this.addInitialDestinationRadioButton();
			this.originalLocationTo = this.parentObject.locationto;

			this.getPortIdFromVisitId();
			this.getRealDuration();
			this.getAllocations();
		}
		let status = ['AU', 'IN', 'FI', 'CL', 'AN', 'RE'];
		if (status.includes(this.parentObject.movementstatuscode)) {
			this.disableByStatus = true;
		}
	},
	watch: {
		'parentObject.visitid'(newVal) {
			if (newVal) {
				this.visitidFixedFilter = {
					groups: [],
					groupOp: 'and',
					rules: [
						{
							field: 'visitid',
							op: 'eq',
							data: newVal
						}
					]
				};
				this.parentObject.movementstatuscode = this.initialStatus;
				this.enableMovNum = true;
				this.getNextMovementNum();
			} else {
				this.visitidFixedFilter = null;
			}
			this.visitidKey += 1;
		},
		'parentObject.origin'(newVal) {
			if (newVal) {
				if (this.parentObject.origin === 'OL') {
					//location selected
					if (this.parentObject.portid) {
						this.portidFixedFilter = {
							groups: [],
							groupOp: 'and',
							rules: [
								{
									field: 'portid',
									op: 'eq',
									data: this.parentObject.portid
								}
							]
						};
					}
					this.enableOriginLocation = true;
					this.enableOriginStop = false;
					this.parentObject.stopfrom = null;
				} else {
					//stop selected
					this.visitidFixedFilter = {
						groups: [],
						groupOp: 'and',
						rules: [
							{
								field: 'visitid',
								op: 'eq',
								data: this.parentObject.visitid
							}
						]
					};
					if (!this.parentObject.stopfrom) {
						this.parentObject.locationfrom = null;
					}
					this.enableOriginStop = true;
					this.enableOriginLocation = false;
				}
			} else {
				this.enableOriginLocation = false;
				this.enableOriginStop = false;
			}
		},
		'parentObject.stopfrom'(newVal) {
			if (newVal !== null) {
				const stopfrom = this.parentObject.stopfrom;
				//this.getLocationFromStop(stopfrom);
			}
		},
		'parentObject.destination'(newVal) {
			if (newVal) {
				if (this.parentObject.destination === 'DL') {
					//location selected
					if (this.parentObject.portid) {
						this.portidFixedFilter = {
							groups: [],
							groupOp: 'and',
							rules: [{ field: 'portid', op: 'eq', data: this.parentObject.portid }]
						};
					}
					this.enableDestinationLocation = true;
					this.enableDestinationStop = false;
					this.parentObject.stopto = null;
				} else {
					//stop selected
					this.visitidFixedFilter = {
						groups: [],
						groupOp: 'and',
						rules: [{ field: 'visitid', op: 'eq', data: this.parentObject.visitid }]
					};
					if (!this.parentObject.stopto) {
						this.parentObject.locationto = null;
					}
					this.enableDestinationStop = true;
					this.enableDestinationLocation = false;
				}
			} else {
				this.enableDestinationLocation = false;
				this.enableDestinationStop = false;
			}
		},
		'parentObject.stopto'(newVal) {
			if (newVal !== null) {
				const stopto = this.parentObject.stopto;
				//this.parentObject.locationto = this.getLocationFromStop(stopto);
			}
		},
		'parentObject.portid'(newVal) {
			if (newVal !== null) {
				this.enableVisit = true;
				if (this.parentObject.portid) {
					this.portidFixedFilter = { groups: [], groupOp: 'and', rules: [{ field: 'portid', op: 'eq', data: this.parentObject.portid }] };
				}
			}
		}
	}
};
</script>
