<template>
	<div>
		<!-- If modal have a "pui-select" or "pui-date-field", set property :overflow="false" -->
		<!-- If fixed width is needed, set property :widthDialog="XXX" -->
		<pui-modal-dialog-form
			:titleText="$t('modal.movement.authorizeMovement')"
			:modelName="modelName"
			:dialogName="authorizeMovement"
			:onShow="onShowAuthorizeMovement"
			:onOk="onOkAuthorizeMovement"
			:showDialogProp="showAuthorizeMovement"
			:overflow="false"
		>
			<template slot="message" slot-scope="data">
				<pui-date-field
					:id="`starttime-${modelName}`"
					:label="$t('form.movement.starttime')"
					v-model="data.modalData.starttime"
					required
					toplabel
					time
				></pui-date-field>
				<pui-date-field
					:id="`endtime-${modelName}`"
					:label="$t('form.movement.endtime')"
					v-model="data.modalData.endtime"
					required
					toplabel
					time
				></pui-date-field>
			</template>
		</pui-modal-dialog-form>

		<pui-modal-dialog-form
			:titleText="$t('modal.movement.initMovement')"
			:modelName="modelName"
			:dialogName="initMovement"
			:onShow="onShowInitMovement"
			:onOk="onOkInitMovement"
			:showDialogProp="showInitMovement"
			:overflow="false"
		>
			<template slot="message" slot-scope="data">
				<pui-date-field
					:id="`starttime-${modelName}`"
					:label="$t('form.movement.starttime')"
					v-model="data.modalData.starttime"
					required
					toplabel
					time
				></pui-date-field>
			</template>
		</pui-modal-dialog-form>

		<pui-modal-dialog-form
			:titleText="$t('modal.movement.finMovement')"
			:modelName="modelName"
			:dialogName="finMovement"
			:onShow="onShowFinMovement"
			:onOk="onOkFinMovement"
			:showDialogProp="showFinMovement"
			:overflow="false"
		>
			<template slot="message" slot-scope="data">
				<pui-date-field
					:id="`endtime-${modelName}`"
					:label="$t('form.movement.endtime')"
					v-model="data.modalData.endtime"
					required
					toplabel
					time
				></pui-date-field>
			</template>
		</pui-modal-dialog-form>
	</div>
</template>

<script>
import EventsMixin from '@/mixins/EventsMixin';

export default {
	name: 'movement-modals',
	mixins: [EventsMixin],
	components: {},
	data() {
		return {
			authorizeMovement: 'authorizeMovement',
			initMovement: 'initMovement',
			finMovement: 'finMovement',
			showAuthorizeMovement: true,
			showInitMovement: true,
			showFinMovement: true
		};
	},
	props: {
		modelName: {
			required: true,
			type: String
		}
	},
	computed: {},
	watch: {},
	methods: {
		onShowAuthorizeMovement(modalData) {
			modalData.starttime = modalData.starttime ? modalData.starttime : modalData.allocations[0].eta;
			modalData.endtime = modalData.endtime ? modalData.endtime : modalData.allocations[0].etd;
		},
		onOkAuthorizeMovement(modalData) {
			return new Promise(async (resolve) => {
				this.$puiRequests.putRequest(
					'/movement/doAuthorize',
					modalData,
					() => {
						this.$puiNotify.success(this.$t('pui9.save.success'));
						this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
						this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

						this.showAuthorizeMovement = false;
						resolve(true);
					},
					(error) => {
						this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
						this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);

						this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

						this.showAuthorizeMovement = false;
						resolve(true);
					}
				);
			});
		},
		onShowInitMovement(modalData) {
			modalData.starttime = modalData.starttime ? modalData.starttime : modalData.allocations[0].eta;
			modalData.endtime = modalData.endtime ? modalData.endtime : modalData.allocations[0].etd;
		},
		onOkInitMovement(modalData) {
			return new Promise(async (resolve) => {
				this.$puiRequests.putRequest(
					'/movement/doInitiate',
					modalData,
					() => {
						this.$puiNotify.success(this.$t('pui9.save.success'));
						this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
						this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

						this.showInitMovement = false;
						resolve(true);
					},
					(error) => {
						this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
						this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);

						this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

						this.showInitMovement = false;
						resolve(true);
					}
				);
			});
		},
		onShowFinMovement(modalData) {
			const lastAllocationIndex = modalData.allocations.length - 1;
			modalData.starttime = modalData.starttime ? modalData.starttime : modalData.allocations[lastAllocationIndex].eta;
			modalData.endtime = modalData.endtime ? modalData.endtime : modalData.allocations[lastAllocationIndex].etd;
		},
		onOkFinMovement(modalData) {
			return new Promise(async (resolve) => {
				this.$puiRequests.putRequest(
					'/movement/doFinalize',
					modalData,
					() => {
						this.$puiNotify.success(this.$t('pui9.save.success'));
						this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
						this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

						this.showFinMovement = false;
						resolve(true);
					},
					(error) => {
						this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
						this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);

						this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

						this.showFinMovement = false;
						resolve(true);
					}
				);
			});
		}
	}
};
</script>
<style lang="postcss">
.headerAuthorize {
	background-color: #f5f7f7;
}
</style>
